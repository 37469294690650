import { createPopper } from '@popperjs/core';
import type { Placement } from '@popperjs/core';
import cn from 'classnames';
import { Component, createRef } from 'react';
import { createPortal } from 'react-dom';

import { stopEvent } from '@webapp/common/lib/ui';

import { Info } from '../icons';

import css from './tooltip.css';

const defaultModifiers = [
    {
        name: 'preventOverflow',
        options: {
            escapeWithReference: true,
            boundariesElement: 'scrollParent'
        }
    }
];

// TODO refactor with hooks

export class Tooltip extends Component<{
    placement?: Placement;
    offset?: [number, number];
    preventClick?: boolean;
    trigger?: ReactNode;
    triggerClassName?: string;
}> {
    public state = {
        visible: false
    };

    private static defaultProps = {
        placement: 'right',
        offset: null
    };

    private bubble = createRef<HTMLDivElement>();
    private node = createRef<HTMLDivElement>();
    private popper;
    private root;

    componentDidMount(): void {
        this.root = document.getElementById('tooltip');

        if (this.node.current && this.bubble.current) {
            this.createPopper();
        }

        // TODO: rethink
        this.forceUpdate(); // force client-side update
    }

    componentDidUpdate(): void {
        if (!this.popper && this.node.current && this.bubble.current) {
            this.createPopper();
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('touchstart', this.handleTouchDocument);

        this.hideBubble();
    }

    handleMouseEnter = (): void => {
        this.showBubble();
    };

    handleMouseLeave = (): void => {
        this.hideBubble();
    };

    handleTouch = (): void => {
        document.addEventListener('touchstart', this.handleTouchDocument, false);

        this.showBubble();
    };

    handleTouchDocument = (e: TouchEvent): void => {
        if (this.bubble.current && this.bubble.current.contains(e.target as HTMLElement)) {
            return;
        }

        document.removeEventListener('touchstart', this.handleTouchDocument);

        this.hideBubble();
    };

    hideBubble = (): void => {
        if (!this.state.visible) {
            return;
        }

        if (this.bubble.current) {
            this.bubble.current.classList.remove(css.visible);
        }

        this.setState({
            visible: false
        });
    };

    onClick = (e): void => {
        const { preventClick } = this.props;
        if (preventClick) {
            stopEvent(e);
        }
    };

    render(): ReactNode {
        const { trigger, triggerClassName } = this.props;

        return (
            <>
                <div
                    className={cn(css[trigger ? 'trigger' : 'icon'], triggerClassName)}
                    ref={this.node}
                    onClick={this.onClick}
                    onMouseOut={this.handleMouseLeave}
                    onMouseOver={this.handleMouseEnter}
                    onTouchStart={this.handleTouch}
                >
                    {this.props.trigger || <Info />}
                </div>
                {this.renderBubble()}
            </>
        );
    }

    renderBubble(): ReactNode {
        const { children } = this.props;

        if (!this.root) {
            return null;
        }

        return createPortal(
            <div
                className={css.bubble}
                ref={this.bubble}
                onMouseLeave={this.handleMouseLeave}
                onMouseOver={this.handleMouseEnter}
            >
                {children}
            </div>,
            this.root
        );
    }

    showBubble = (): void => {
        if (this.state.visible || !this.bubble.current) {
            return;
        }

        this.bubble.current.classList.add(css.visible);

        this.setState({
            visible: true
        });
    };

    private createPopper = (): void => {
        const { offset, placement } = this.props;
        const modifiers: Array<any> = defaultModifiers;

        if (offset) {
            modifiers.push({
                name: 'offset',
                options: { offset }
            });
        }

        this.popper = createPopper(this.node.current, this.bubble.current, {
            placement,
            strategy: 'fixed',
            modifiers
        });
    };
}
